import { stringify, parse } from 'qs';
import { isObject, hasProtocol } from 'utils';
import { HOST_ENDPOINT } from 'config/endpoints';
import { datePresets } from 'utils/dates';
import { createBrowserHistory } from 'history';

/**
 * Transform valid url params
 * @param {object} state
 * @return {Object}
 */
export const getParsedParams = (state = {}) => {
	const hash = window.location.hash.replace('#', '');
	const paramObj = {
		...parse(window.location.search, { ignoreQueryPrefix: true }),
		...(hash && { hash })
	};
	const availableFilters = ['filters', 'sortBy', 'sortDirection', 'page', 'subsection'];

	const parsedParams = { hash };

	if (!Object.keys(paramObj).length) return parsedParams;

	try {
		availableFilters.forEach(filterKey => {
			const currentFilter = paramObj[filterKey];

			if (currentFilter) {
				if (isObject(currentFilter)) {
					parsedParams[filterKey] = Object.keys(currentFilter).reduce((accum, key) => {
						let accumulator = { ...accum };

						const { filters = [] } = state;

						const filterData = filters.find(filter => filter.name === key);

						const urlFilterValue = currentFilter[key];

						if (filterData) accumulator = { ...accum, [key]: urlFilterValue };

						return accumulator;
					}, {});
				} else {
					parsedParams[filterKey] = currentFilter;
				}
			}
		});

		return parsedParams;
	} catch (error) {
		console.error(error);
		return parsedParams;
	}
};

/**
 * Function for take a current browser history or create new history if necessary
 */
let history = {
	instance: null
};

export const clearHistoryCache = () => {
	history = {
		instance: null
	};
};

export const getCurrentHistory = () => {
	if (history.instance !== null) return history.instance;
	history.instance = createBrowserHistory();
	return history.instance;
};

export const modifyParams = (filters, componentsByName, isDefValue) =>
	Object.keys(filters).reduce((accum, key) => {
		const filterValue = filters[key];

		const { componentAttributes = {}, component: componentName } = componentsByName[key] || {};

		if (
			typeof filterValue === 'object' &&
			componentName === 'DateTimePicker' &&
			componentAttributes.selectRange &&
			componentAttributes.selectDate &&
			componentAttributes.presets
		) {
			const preset = Object.keys(datePresets).reduce((presetValue, presetkey) => {
				const { start, end } = datePresets[presetkey];
				if (presetkey === filterValue.from) filterValue.from = start.toISOString();
				if (presetkey === filterValue.to) filterValue.to = end.toISOString();
				if (
					!presetValue &&
					start.toISOString() === filterValue.from &&
					end.toISOString() === filterValue.to
				) {
					return datePresets[presetkey];
				}

				return presetValue;
			}, null);
			return {
				...accum,
				[key]: preset
					? {
							from: isDefValue ? preset.start.toISOString() : preset.startFilter,
							to: isDefValue ? preset.end.toISOString() : preset.endFilter
					  }
					: filterValue
			};
		}

		return { ...accum, [key]: filterValue };
	}, {});

export const getComponentsByName = filtersComponents =>
	filtersComponents.reduce((accum, component) => ({ ...accum, [component.name]: component }), {});

/**
 * Function set current params in url
 * @param {object} objParams - current filters applyed
 */
export const updateUrlFilters = (objParams, filtersComponents) => {
	let paramFiltersModified = {};

	const componentsByName = getComponentsByName(filtersComponents);

	if (objParams.filters) {
		paramFiltersModified = modifyParams(objParams.filters, componentsByName);
	}

	const { preview } = parse(window.location.search, { ignoreQueryPrefix: true });

	const formattedParams = objParams.filters
		? { ...objParams, filters: paramFiltersModified }
		: objParams;

	if (preview) formattedParams.preview = preview;

	const urlParams = stringify(
		formattedParams,
		{ encodeValuesOnly: true },
		{ addQueryPrefix: true },
		{ arrayFormat: 'index' }
	);

	const currentHistory = getCurrentHistory();

	currentHistory.push({
		pathname: window.location.pathname,
		search: urlParams
	});
};

export const getLocationParts = () => {
	const { pathname } = window.location;

	const parts = pathname === '/' ? ['home', 'dashboard'] : pathname.split('/').filter(Boolean);

	const [service, namespace, method, parameter] = parts;

	return [service, namespace, method, parameter];
};

/**
 * Build the url based on location / service / namespace / method
 * e.g: /csx/claim-type/browse
 * @param {string} prefix - prefix schema|data|filter
 * @param {bool} useEndpointParam - append endpoint params if necessary (for ids or additional data)
 * @returns {string} url
 */
export const buildUrlFromLocationPath = (prefix, useEndpointParam = false) => {
	const parts = getLocationParts();

	const [service, namespace, method, endpoint] = parts;
	if (!service || !namespace || !method) return false;
	if (useEndpointParam && !endpoint) return false;

	return `${HOST_ENDPOINT}/${prefix}/${service}/${namespace}/${method}/${
		useEndpointParam ? endpoint : ''
	}`;
};

export const getServiceNamespaceURL = () => {
	const [service, namespace] = getLocationParts();

	return `/${service}/${namespace}`;
};

/** Goes to the specified method view within the same service/namespace
 * @param {string} method name.
 */

export const goToMethodURL = method => {
	const currentHistory = getCurrentHistory();
	currentHistory.push(`${getServiceNamespaceURL()}/${method}`);
};

export const goToURL = (path, target) => {
	const currentHistory = getCurrentHistory();

	if (target === '_blank') return window.open(path, target);
	if (hasProtocol(path)) window.location.href = path;
	else currentHistory.push(path);
};

/** Checks history and if the fromBrowse flag is true (passed in the Browse links),
 * returns to the previous page to keep applied filters if existing.
 * If the fromBrowse flag does not exist or is set to false, it redirects to the Browse view. */
export const backToBrowse = navigationFlag => {
	const currentHistory = getCurrentHistory();
	const { state = {} } = currentHistory.location;
	const fromBrowseFlag =
		navigationFlag === 'redirectToBrowseEntity' ? state.fromBrowseView : state.fromBrowse;
	if (fromBrowseFlag) {
		currentHistory.goBack();
	} else {
		goToMethodURL('browse');
	}
};

export const goToLogin = () => {
	const { pathname, search, hash } = window.location;
	const redirURL = encodeURIComponent(`${pathname}${search}${hash}`);

	window.location.href = `/login${stringify({ redir: redirURL }, { addQueryPrefix: true })}`;
};

/** Updates the current url adding or updating the provided search params while keeping the rest of the data.
 * @param {string} param
 * @param {string} newValue
 */
export const updateSearchParams = (param, newValue) => {
	if (!param) return;

	const { hash, search } = window.location;
	const searchParams = parse(search, { ignoreQueryPrefix: true });

	searchParams[param] = newValue;

	if (!newValue) delete searchParams[param];

	const filters = stringify(searchParams, { encodeValuesOnly: true }, { addQueryPrefix: true });

	const url = `?${filters}${hash}`;

	goToURL(url);
};

export const getPreviewUrlParams = () => {
	const { preview = '' } = parse(window.location.search, { ignoreQueryPrefix: true });

	const [id, section, subsection] = preview.split('_');

	return {
		id,
		section,
		subsection
	};
};

/**
 * Make sortable and page params for call request
 * @param {Array} sortableFields
 * @returns {Object}
 */
export const getInitialParams = (sortableFields = []) => {
	const { sortBy, sortDirection, page } = getParsedParams();

	const [defaultSort = {}] = sortableFields.filter(({ isDefaultSort }) => isDefaultSort);

	return {
		sortBy: sortBy || defaultSort.name,
		sortDirection: sortDirection || defaultSort.initialSortDirection,
		page
	};
};
