import { getPageData } from 'utils/api';

const source = {
	service: 'tms',
	namespace: 'route-planning',
	method: 'details'
};

export default async id => {
	try {
		if (!id) return { data: {}, error: 'No route planning ID provided' };

		const routePlanningDetails = await getPageData({ source, endpointParameters: { id } });
		return { data: routePlanningDetails };
	} catch (error) {
		console.error(`Error fetching route planning details by id: ${error}`);
		return { data: {}, error: error?.message };
	}
};
