import { getPageData } from 'utils/api';

const source = {
	service: 'tms',
	namespace: 'route-planning',
	method: 'get'
};

export default async id => {
	try {
		if (!id) throw new Error('No route planning ID provided');

		const routePlanning = await getPageData({ source, endpointParameters: { id } });
		return { data: routePlanning, errors: [...(routePlanning?.errors || [])] };
	} catch (error) {
		console.error(`Error fetching route planning by id: ${error}`);
		return { data: {}, errors: [error?.message] };
	}
};
