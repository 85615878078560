/* eslint-disable no-underscore-dangle */

import DateHandler from 'libs/shared/DateHandler';

export default class ShippingFormatter {
	/**
	 * Gets the address of a shipping
	 * @param {object} schedule - The shipping object
	 * @returns the shipping address
	 */
	static _getAddress(shipping = {}) {
		if (shipping?.address) return shipping?.address;
		const { dropoff: { street = '', number = '', state = '', city = '' } = {} } = shipping;
		return `${street} ${number}, ${state}, ${city}`;
	}

	/**
	 * Gets delivery date of a shipping
	 * @param {object} schedule - The shipping object
	 * @returns the shipping delivery date
	 */
	static _getDeliveryDate(schedule = {}) {
		const { start = '', end = '' } = schedule;

		return {
			start: DateHandler.format(start, 'dd/MM HH:mm'),
			end: DateHandler.format(end, 'dd/MM HH:mm')
		};
	}

	/**
	 * Gets shipping information
	 * @param {object} shipping - The shipping object
	 * @param {object} shippingsData - All the shipping data to obtain the current shipping data
	 * @returns some shipping information
	 */
	static _getShippingData(shipping = {}, shippingsData = {}) {
		const { actions = [] } = shipping;

		const shippingDataInAction = shippingsData[(actions[0]?.shippingId)] || {};

		return {
			address: this._getAddress(shipping),
			deliveryDate: this._getDeliveryDate(shipping?.schedule || shippingDataInAction?.schedule),
			packageQuantity: shipping?.packageQuantity || shippingDataInAction?.packageQuantity
		};
	}

	/**
	 * Fills the actions with the current shipping data
	 * @param {object} shipping - The shipping object
	 * @param {object} shippingsData - All the shipping data to obtain the current shipping data
	 * @returns the actions of the shipping with extra shipping data
	 */
	static _fillActionsWithCurrentShippingData(shipping = {}, shippingsData = {}) {
		const { actions = [] } = shipping;

		return actions.map(currentAction => ({
			...shippingsData[(currentAction?.shippingId)],
			...currentAction
		}));
	}

	/**
	 * Formats a shipping
	 * @param {object} shipping - The shipping object
	 * @param {object} warehousesData - All the warehouse data to obtain the current warehouse
	 * @param {object} shippingsData - All the shipping data to obtain the current shipping data
	 * @returns a shipping formatted depending on its type
	 */
	static format(shipping = {}, warehousesData = {}, shippingsData = {}) {
		const { warehouseId = '', actions = [], type = '', hasToReturnWarehouse = false } = shipping;

		if (type === 'warehouse' || warehouseId) {
			const currentWarehouse = warehousesData[warehouseId];

			return {
				...shipping,
				...currentWarehouse,
				type: 'warehouse',
				action: !hasToReturnWarehouse ? actions[0]?.type : 'return'
			};
		}

		const { address = '', deliveryDate = {}, packageQuantity = 0 } = this._getShippingData(
			shipping,
			shippingsData
		);

		return {
			...shipping,
			id: shipping?.id || crypto.randomUUID(),
			type: 'shipping',
			address,
			deliveryDate,
			packageQuantity,
			actions: this._fillActionsWithCurrentShippingData(shipping, shippingsData)
		};
	}
}
