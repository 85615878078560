import React from 'react';
import PropTypes from 'prop-types';
import { useDevices } from 'utils/hooks';
import translationHOC from 'hocs/translationHOC';
import { colors } from 'theme';
import styled from './styles';

export const UnpaidHeader = ({ t, TextComponent }) => {
	const { mobile } = useDevices();

	return (
		<styled.Container>
			<styled.Icon name="exclamation_triangle" color={colors.white} size={!mobile ? 32 : 24} />
			<TextComponent color={colors.white} fontSize="baseSmall">
				<styled.BoldText>¡{t('common.title.attention')}!</styled.BoldText>{' '}
				<span>{t('common.title.subscriptionWillBeCancelled')}</span>
			</TextComponent>
		</styled.Container>
	);
};

UnpaidHeader.propTypes = {
	TextComponent: PropTypes.oneOfType([PropTypes.elementType, PropTypes.func]).isRequired
};

export default translationHOC(UnpaidHeader);
