import Map from 'libs/delivery/Map';

export default (routesList = []) => {
	if (!routesList.length) return [];
	return routesList.reduce((acc, { points = [], polylines = [], color = '' }) => {
		if (!points.length) return [];
		const formattedDataToMap = Map.format(points, polylines, color);
		return [...acc, formattedDataToMap];
	}, []);
};
