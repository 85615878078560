/* eslint-disable no-console */
import { colors } from 'theme';
import { isObject } from 'utils';

const AVAILABLE_COLORS = [
	'violetHover',
	'fizzGreen',
	'skyBlue',
	'orange',
	'magenta',
	'blue',
	'greenPressed',
	'lightBlue',
	'yellowPressed',
	'darkBlue'
];
let currentIndexColor = 0;

export const getAction = ({ relatedShippingId, action }) => {
	const ACTIONS = {
		pickup: relatedShippingId ? 'pickupDropoff' : 'dropoff'
	};

	return ACTIONS[action] || 'pickup';
};

export const getColor = () => {
	const availableIndexes = AVAILABLE_COLORS.length;
	const currentColor = AVAILABLE_COLORS[currentIndexColor];
	currentIndexColor = (currentIndexColor + 1) % availableIndexes;

	return colors[currentColor] || colors.darkGrey;
};

/**
 * Formats API data.
 * @param  {array} [shippingsArray] - An array of shipping by warehouse.
 * @param  {object} [shippingsById] - A object with shipping data from its ids.
 * @param  {boolean} [isDropoff] - A boolean to know if has dropof action
 * @returns {array}
 */
const getShippings = (shippingsArray, shippingsById, color, isDropoff = false) =>
	shippingsArray
		.map(shipping => {
			const filtered = shippingsById[shipping.shippingId];
			const parsedAction = getAction(shipping);
			return {
				...filtered,
				...shipping,
				type: 'shipping',
				color,
				parsedAction
			};
		})
		.filter(sh => (!isDropoff ? sh.action !== 'dropoff' : sh.action === 'dropoff'));

/**
 * Get data for the api and format it to  be used in the app.
 * @param  {object} [data] - An object of the API.
 * @returns {array}
 */
export const formatFirstStepData = data => {
	const { shippingsByWarehouse = [], shippings: dataForShippings = {} } = data || {};

	if (!Array.isArray(shippingsByWarehouse) || !isObject(dataForShippings)) return [];

	const formattedData = [];
	shippingsByWarehouse.forEach(({ warehouse, shippings }) => {
		const currentColor = getColor();

		const currentWarehouse = {
			...(warehouse || {}),
			type: 'warehouse',
			action: 'pickup',
			stops: getShippings(shippings, dataForShippings, currentColor),
			color: currentColor
		};

		currentWarehouse.color = currentColor;

		const dropoffShippings = getShippings(
			shippings,
			dataForShippings,
			currentWarehouse.color,
			true
		).map(drop => ({ ...drop, ...(drop.action === 'dropoff' && { action: 'pickup' }) }));

		formattedData.push(currentWarehouse);
		if (dropoffShippings.length) formattedData.push(...dropoffShippings);
	});

	return formattedData;
};

export const formatDataForMap = data => {
	if (!data) return;

	const { shippings, shippingsByWarehouse } = data || {};

	const newData = shippingsByWarehouse.reduce(
		(accum, item) => {
			const { shippings: stops, warehouse } = item;

			const completeStops = stops.map(stop => {
				const shippingData = shippings[(stop?.shippingId)] || {};

				if (stop.action === 'pickup' && !warehouse?.coordinates) {
					warehouse.type = 'warehouse';
					warehouse.coordinates = [...shippingData?.pickup?.coordinates];
				}

				const includesCoord = coords => (!coords ? undefined : [...coords]);

				const coordinates =
					stop?.action === 'pickup'
						? includesCoord(shippingData?.dropoff?.coordinates)
						: includesCoord(shippingData?.pickup?.coordinates);

				return {
					...stop,
					...shippingData,
					coordinates,
					type: 'shipping'
				};
			});

			accum.warehousesInfo.push(warehouse);
			accum.shippingsInfo.push(...completeStops);

			return accum;
		},
		{ warehousesInfo: [], shippingsInfo: [] }
	);
	return newData;
};

export const compareLengths = (firstValue, secondValue) => {
	if (!Array.isArray(firstValue) || !Array.isArray(secondValue)) return;
	return firstValue.length === secondValue.length;
};

/**
 * Get formatted data and format by Id.
 * @param  {array} [data] - Formatted array with data bay origin and destination
 * @returns {object}
 */
export const extractDataById = data =>
	data.reduce((result, { id, color, type }) => {
		if (type !== 'warehouse') return result;
		return {
			...result,
			[id]: color
		};
	}, {});

/**
 * Get related shippings ids from shippings with this key
 * @param {array} shippings - Array of shippings objects
 * @returns {array} Array of relatedShippingsIds
 * @example
 * const shippings = [{id: '1', relatedShippingId: '6635069bf8'},{id: '2'},{id: '3', relatedShippingId: '662bcac4e4'}]
 * const relatedShippingsIds = getRelatedShippingsIds(shippings);
 * // relatedShippingsIds = ['6635069bf8','662bcac4e4']
 */
export const getRelatedShippingsIds = shippings =>
	shippings.map(shipping => shipping?.relatedShippingId).filter(Boolean);
