/* eslint-disable no-underscore-dangle, class-methods-use-this */
import Shipping from '../Shipping';

export default class RouteFormatter {
	/**
	 * Creates a new route
	 * @param {object} route - a route object
	 */
	constructor(route = {}) {
		this.route = route;
	}

	/**
	 * Gets the route name
	 * @readonly
	 * @returns the route name
	 */
	get name() {
		const { warehouses = {} } = this.route;
		const [currentWarehouse] = Object.values(warehouses);
		return currentWarehouse?.name || currentWarehouse?.id;
	}

	/**
	 * Gets the warehouse data
	 * @readonly
	 * @returns warehouse information
	 */
	get warehouse() {
		const { warehouses = {} } = this.route;
		const [currentWarehouse] = Object.values(warehouses);
		return { id: currentWarehouse?.id };
	}

	/**
	 * Formats the stops of a route
	 * @returns The formatted stops using Shipping class
	 */
	_formatStops() {
		const { stops: routeStops = [], warehouses = {}, shippings = {}, color = '' } = this.route;
		return routeStops.map(routeStop =>
			Shipping.format({ ...routeStop, color }, warehouses, shippings)
		);
	}

	/**
	 * Formats a route entity
	 * @returns The formatted route with specific properties
	 */
	format() {
		return {
			...this.route,
			name: this.name,
			warehouse: this.warehouse,
			stops: this._formatStops()
		};
	}
}
