/* eslint-disable class-methods-use-this */
import promiseWrapper from 'utils/promiseWrapper';
import GOOGLE_MAPS_API_KEY from 'config/googleMapsApiKey';
import { getPolylineDirections, parseCoordsForPolylines, validateCoordinates } from './helpers';

export default class Directions {
	/**
	 * Gets the google maps api key
	 * @readonly
	 * @returns google maps api key
	 */
	static get googleMapsApiKey() {
		return GOOGLE_MAPS_API_KEY;
	}

	/**
	 * Gets the directions api url
	 * @readonly
	 * @returns directions api url
	 */
	static get directionsUrl() {
		return 'https://routes.googleapis.com/directions/v2:computeRoutes';
	}

	/**
	 * Gets the maximum number of waypoints per request to make
	 * @readonly
	 * @returns maximum number of waypoints
	 */
	static get maxWaypointsPerRequest() {
		return 25;
	}

	/**
	 * Gets the directions and route data using google directions api
	 * @param {array} points - a list of markers
	 * @param {function} callbackOnSuccessDirections - a callback to invoke if the request was successful
	 * @param {function} callbackOnErrorDirections - a callback to invoke if the request was unsuccessful
	 * @returns the coordinates of a route and its data
	 */
	static async getDirections({
		points = [],
		callbackOnSuccessDirections = () => {},
		callbackOnErrorDirections = () => {}
	}) {
		const validCoordinates =
			Array.isArray(points) &&
			points?.filter(coord => validateCoordinates(coord.position)).filter(Boolean);

		const waypoints = validCoordinates?.length > 2 ? validCoordinates.slice(1, -1) : [];

		const parsedCoords = parseCoordsForPolylines(validCoordinates);
		const [validDirections, directionsError] = await promiseWrapper(
			getPolylineDirections({
				coordinates: parsedCoords,
				waypoints,
				googleMapsApiKey: this.googleMapsApiKey,
				directionsUrl: this.directionsUrl,
				maxWaypointsPerRequest: this.maxWaypointsPerRequest
			})
		);
		if (directionsError) {
			if (callbackOnErrorDirections) callbackOnErrorDirections(directionsError);
			return directionsError;
		}

		const { allCoords, route: routeData } = validDirections;

		if (callbackOnSuccessDirections) callbackOnSuccessDirections(routeData);

		return { allCoords, routeData };
	}
}
