import axios from 'axios';
import { decode } from '@mapbox/polyline';
import formatWaypoints from './formatWaypoints';

/**
 * Gets polylines data using the google directions url api
 * @param {string} directionsUrl - the google directions api url
 * @param {array} waypointsChunks - a list of waypoints
 * @param {string} googleMapsApiKey - the google maps api key for make the request
 * @returns the coordinates list and the route data
 */
export default async (directionsUrl = '', waypointsChunks = [], googleMapsApiKey = '') => {
	const headers = {
		'content-type': 'application/json',
		'x-goog-api-key': googleMapsApiKey,
		'x-goog-fieldmask': '*'
	};

	try {
		const responses = await Promise.all(
			waypointsChunks.map(async currentChunk => {
				const formattedWaypoints = formatWaypoints(currentChunk);

				const { data } = await axios.post(directionsUrl, formattedWaypoints, { headers });
				const points = decode(data?.routes[0]?.polyline?.encodedPolyline);
				const coords = points.map(point => ({ lat: point[0], lng: point[1] }));

				return { coords, route: data?.routes[0] };
			})
		);

		const allCoords = responses.flatMap(response => response.coords);
		const route = responses.length ? responses[responses.length - 1].route : {};

		return { allCoords, route };
	} catch (error) {
		console.error(`Error fetching route data by google maps`, error);
		return { coords: [], route: {} };
	}
};
