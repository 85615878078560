import RoutePlanning from 'libs/delivery/RoutePlanning';
import simulateRouteByOptimize from 'services/tms/simulateRoute';

export default async ({ originalRoute = {}, requestBody = {}, routeColor = '' }) => {
	try {
		const simulatedRoute = await simulateRouteByOptimize(requestBody);

		if (simulatedRoute?.error) throw new Error(simulatedRoute?.error);

		const [updatedRoute] = await RoutePlanning.formatRoutes([
			{ ...originalRoute, ...simulatedRoute, color: routeColor }
		]);
		return updatedRoute;
	} catch (error) {
		console.error(error);
		return { response: null, error: error?.message };
	}
};
