import Shipping from 'libs/delivery/Shipping';

export default (unassignedShippings = {}) => {
	const { groupShippings = [], shippings: shippingsData = {} } = unassignedShippings;
	return groupShippings?.map(group => ({
		...group,
		shippings: group?.shippings?.map(shipping => {
			const currentShipping = shippingsData[(shipping?.shippingId)];
			return Shipping.format(currentShipping, group?.warehouse, shippingsData);
		})
	}));
};
