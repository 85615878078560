import ShippingFormatter from '../ShippingFormatter';
import { hasValidCoords } from './helpers';

export default class Shipping {
	/**
	 * Gets the type of a current shipping
	 * @param {object} shipping - The shipping object
	 * @returns the shipping type
	 */
	static getType(shipping = {}) {
		const { kind = '' } = shipping;
		return kind;
	}

	/**
	 * Gets the coordinates of a current shipping
	 * @param {object} shipping - The shipping object
	 * @returns the coordiantes of the shipping
	 */
	static getCoordinates(shipping = {}) {
		const { coordinates = [], dropoff = {} } = shipping;
		const coordsToValidate = coordinates?.length ? coordinates : dropoff?.coordinates;
		return hasValidCoords(coordsToValidate) ? coordsToValidate : [];
	}

	/**
	 * Checks if the shipping type is pickup
	 * @param {object} shipping - The shipping object
	 * @returns true if the shipping is pickup, otherwise false
	 */
	static isPickup(shipping = {}) {
		const { actions = [] } = shipping;
		return actions[0]?.type === 'pickup';
	}

	/**
	 * Checks if the shipping type is dropoff
	 * @param {object} shipping - The shipping object
	 * @returns true if the shipping is dropoff, otherwise false
	 */
	static isDropoff(shipping = {}) {
		const { actions = [] } = shipping;
		return actions[0]?.type === 'dropoff';
	}

	/**
	 * Checks if the shipping is a warehouse and its type is dropoff
	 * @param {object} shipping - The shipping object
	 * @returns true if meets the validation, otherwise false
	 */
	static hasToReturnWarehouse(shipping = {}) {
		return this.getType(shipping) === 'warehouse' && this.isDropoff(shipping);
	}

	/**
	 * Formats a shipping
	 * @param {object} shipping - The shipping object
	 * @param {object} warehousesData - All the warehouse data to obtain the current warehouse
	 * @param {object} shippingsData - All the shipping data to obtain the current shipping data
	 * @returns a shipping formatted using the format method of the ShippingFormatted class
	 */
	static format(shipping, warehousesData, shippingsData) {
		const shippingWithFormatData = {
			...shipping,
			type: this.getType(shipping),
			coordinates: this.getCoordinates(shipping),
			hasToReturnWarehouse: this.hasToReturnWarehouse(shipping)
		};

		return ShippingFormatter.format(shippingWithFormatData, warehousesData, shippingsData);
	}
}
