import { callRequest, getFormattedEndpointParameters } from 'utils/request';
import { actions as asyncDependenciesActions } from 'modules/asyncDependencies';
import * as types from './types';
import { setRemoteActionsCache, fetchRemoteSections } from '../page/actions';

const fetchDataRequest = () => ({
	type: types.FETCH_DATA_REQUEST
});

export const fetchDataSuccess = data => ({
	type: types.FETCH_DATA_SUCCESS,
	data
});

const fetchDataFailure = status => ({
	type: types.FETCH_DATA_FAILURE,
	status
});

export const setReady = isReady => ({
	type: types.IS_READY,
	isReady
});

export const setTabVisible = ({ name }) => ({
	type: types.SET_TAB_VISIBLE,
	name
});

const fetchRemoteActions = async (dispatch, remoteActionsSource, data) => {
	const {
		source,
		sourceEndpointParameters = [],
		title,
		translateTitle,
		modalSize
	} = remoteActionsSource;

	const { endpointParameters, filters } = getFormattedEndpointParameters(
		sourceEndpointParameters,
		data
	);

	try {
		const actions = await callRequest(source, filters, endpointParameters);

		const remoteActions = { actions, titleSettings: { title, translateTitle }, modalSize };

		dispatch(setRemoteActionsCache(remoteActions));
	} catch (error) {
		console.error(error);
	}
};

export const fetchData = (source, sourceEndpointParameters) => async (dispatch, getState) => {
	const {
		settings: state,
		page: {
			schema: { remoteActions, dependencies }
		}
	} = getState();

	if (state.isFetching) return;

	dispatch(fetchDataRequest());

	if (!source) {
		dispatch(fetchRemoteSections());
		dispatch(fetchDataSuccess({}));
		return;
	}

	const { endpointParameters } = getFormattedEndpointParameters(
		sourceEndpointParameters,
		state.data
	);

	try {
		const data = await callRequest(source, {}, endpointParameters);

		dispatch(fetchDataSuccess(data));
		dispatch(fetchRemoteSections());

		dispatch(
			asyncDependenciesActions.executeGetAsyncDependencies(
				dependencies,
				data,
				'globalDependencies',
				'edit'
			)
		);

		if (remoteActions) fetchRemoteActions(dispatch, remoteActions, data);
	} catch ({ response = {} }) {
		dispatch(fetchDataFailure({ status: response.status }));
	}
};
