/* eslint-disable class-methods-use-this */
import { ERROR_MOMENT_MESSAGE } from './constants';
import { LOCALES } from './locales';
import { isValidDate, datesFormat } from './helpers';

/**
 * A class to handle date formatting and validation with support for multiple locales
 */
class DateHandler {
	/**
	 * Creates an instance of DateHandler
	 * @param {Object} config - Configuration options
	 * @param {"en" | "es" | "pt-BR" | "pt"} [config.locale="en"] - The initial locale
	 * @example
	 * const Dates = new DateHandler({ locale: 'es' });
	 */
	constructor(config = { locale: 'en' }) {
		if (DateHandler.instance) return DateHandler.instance;

		const { locale } = config;
		this.setLocale(locale);

		DateHandler.instance = this;
	}

	/**
	 * Updates the locale dynamically
	 * @param {"en" | "es" | "pt-BR" | "pt"} language - The new language to apply
	 * @example
	 * const Dates = new DateHandler({ locale: 'en' });
	 * Dates.setLocale('es'); // Switches locale to Spanish
	 */
	setLocale(language) {
		this.language = LOCALES[language] ? language : 'en';
		this.locale = LOCALES[this.language];
	}

	/**
	 * Validates if a given date is valid
	 * @param {Date|string} date - The date to validate
	 * @returns {boolean} True if the date is valid, otherwise false
	 * @example
	 * const Dates = new DateHandler({ locale: 'es' });
	 * Dates.isValid('2024-10-04T21:26:33.801Z'); // true
	 * Dates.isValid('invalid date'); // false
	 */
	isValid(date) {
		return isValidDate(date);
	}

	/**
	 * Determines if a given format string follows Moment.js syntax.
	 * It does this by attempting to format a date and checking for errors.
	 * @param {string} [format=''] - The format string to check.
	 * @returns {boolean} True if the format is in Moment.js style, otherwise false.
	 * @example
	 * const Dates = new DateHandler({ locale: 'es' });
	 * Dates.isMomentFormat('YYYY-MM-DD'); // true
	 * Dates.isMomentFormat('dd/MM/yyyy'); // false
	 */
	isMomentFormat(format = '') {
		try {
			const date = new Date();
			datesFormat(date, format);
			return { isIncompatible: false, error: null };
		} catch (error) {
			if (error?.message?.includes(ERROR_MOMENT_MESSAGE))
				return { isIncompatible: true, error: error.message };
			return { isIncompatible: false, error: null };
		}
	}

	/**
	 * Formats a date or a range of dates based on the current locale
	 * @param {Date | string} date - The date(s) to format
	 * @param {string} [format="P"] - The format string (supports moment.js and date-fns styles)
	 * @returns {string | null} The formatted date string, or null if formatting fails
	 * @example
	 * const Dates = new DateHandler({ locale: 'es' });
	 * Dates.format('2024-11-09T11:00:00.000Z', 'dd/MM/yyyy HH:mm'); // '09/11/2024 11:00'
	 */
	format(date, format = 'dd/MM/yyyy HH:mm') {
		try {
			if (!this.isValid(date)) throw new Error('Invalid date');

			const formattedDate = datesFormat(date, format, { locale: this.locale });

			if (this.isMomentFormat(format).isIncompatible && process.env.NODE_ENV === 'production')
				// eslint-disable-next-line no-console
				console.warn(this.isMomentFormat(format).error);

			return formattedDate;
		} catch (error) {
			// eslint-disable-next-line no-console
			if (error?.message?.includes(ERROR_MOMENT_MESSAGE)) return console.warn(error.message);

			console.error(error);

			return null;
		}
	}
}

const DateHandlerService = new DateHandler();

export default DateHandlerService;
