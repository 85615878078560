import React from 'react';
import PropTypes from 'prop-types';
import { copyToClipboard, debounce } from 'utils';
import translationHOC from 'hocs/translationHOC';
import styled from './styles';

class Code extends React.Component {
	shouldComponentUpdate(nextProps) {
		return nextProps.value !== this.props.value;
	}

	getValueByString = () => {
		const { language, readOnly, value } = this.props;
		if (language === 'json' && readOnly) {
			try {
				return this.parseJSON(JSON.parse(value));
			} catch (error) {
				return value;
			}
		}
		return value;
	};

	parseJSON = json => {
		try {
			return JSON.stringify(json, null, 4);
		} catch (error) {
			return json;
		}
	};

	changeValue = val => {
		const { language, onChange = () => null } = this.props;
		let currentValue = val;

		try {
			if (language === 'json') currentValue = JSON.parse(val);
		} catch {} // eslint-disable-line

		onChange(currentValue);
	};

	render() {
		const {
			value,
			language,
			name,
			readOnly,
			canCopy,
			minLines,
			maxLines,
			fontSize,
			resetCb,
			applyCb,
			t
		} = this.props;

		const content = typeof value === 'string' ? this.getValueByString() : this.parseJSON(value);

		return (
			<styled.Wrapper>
				<styled.CodeViewer
					mode={language}
					name={name}
					value={content}
					readOnly={readOnly}
					width="100%"
					showPrintMargin={false}
					wrapEnabled
					theme="monokai"
					minLines={minLines}
					maxLines={maxLines}
					fontSize={fontSize}
					highlightActiveLine={false}
					navigateToFileEnd={false}
					onChange={debounce(this.changeValue, 400)}
					setOptions={{ useWorker: false }}
				/>
				{resetCb && (
					<styled.BaseButton variant="outlined" type="button" onClick={resetCb}>
						{t('common.action.restartChanges')}
					</styled.BaseButton>
				)}

				{applyCb && (
					<styled.BaseButton variant="outlined" type="button" onClick={applyCb}>
						{t('common.action.applyChanges')}
					</styled.BaseButton>
				)}
				{canCopy && (
					<styled.CopyButton
						type="button"
						variant="outlined"
						icon="copy"
						onClick={() => copyToClipboard(content)}
					>
						{t('common.action.copy')}
					</styled.CopyButton>
				)}
			</styled.Wrapper>
		);
	}
}

Code.defaultProps = {
	language: 'json',
	minLines: 10,
	maxLines: 25,
	fontSize: 13,
	canCopy: true
};

Code.propTypes = {
	language: PropTypes.string,
	name: PropTypes.string,
	readOnly: PropTypes.bool,
	t: PropTypes.func,
	onChange: PropTypes.func,
	resetCb: PropTypes.func,
	applyCb: PropTypes.func,
	canCopy: PropTypes.bool,
	minLines: PropTypes.number,
	maxLines: PropTypes.number,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
	fontSize: PropTypes.number
};

export default translationHOC(Code);
