import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Shortcut, ShortcutProvider } from '@shopify/react-shortcuts';
import { actions } from 'modules/navbar';
import { getAuthMultipleClients } from 'utils/auth';

const Shortcuts = ({ children, shortcuts, toggleModal }) => {
	const { isOpenSearch, showSchemaModal } = useSelector(state => state.navbar);
	const dispatch = useDispatch();
	const {
		openSearch,
		closeSearch,
		openClient,
		closeAll,
		closeSchemaModal,
		openSchemaModal
	} = actions;
	const hasMultipleClients = getAuthMultipleClients();
	const toggleSearch = () => (isOpenSearch ? closeSearch() : openSearch());
	const toggleSchemaModal = () => (showSchemaModal ? closeSchemaModal() : openSchemaModal());

	const globalShortcuts = [
		{
			ordered: ['?'],
			held: ['Shift'],
			onMatch: () => toggleModal(prevState => !prevState)
		},
		{
			ordered: ['F'],
			held: ['Shift'],
			onMatch: () => dispatch(toggleSearch())
		},
		{
			ordered: ['D'],
			held: ['Shift'],
			onMatch: () => dispatch(toggleSchemaModal())
		},
		{
			ordered: ['c'],
			held: [['Control', 'Meta'], ['Control', 'Alt']],
			onMatch: () => hasMultipleClients && dispatch(openClient())
		},
		{
			ordered: ['Escape'],
			onMatch: () => dispatch(closeAll())
		}
	];

	return (
		<ShortcutProvider>
			{[...globalShortcuts, ...shortcuts].map((props, index) => {
				const key = `shortcut-${index}`;
				return <Shortcut key={key} {...props} />;
			})}
			{children}
		</ShortcutProvider>
	);
};

Shortcuts.propTypes = {
	shortcuts: PropTypes.arrayOf(PropTypes.shape({})),
	toggleModal: PropTypes.func,
	children: PropTypes.node
};

export default Shortcuts;
