/* eslint-disable class-methods-use-this, no-underscore-dangle */
import { getColor } from 'utils/route';
import Directions from '../Directions';
import RouteFormatter from '../RouteFormatter';
import Shipping from '../Shipping';
import { getSelectedDriverByWarehouse, getShippingIcon, getWarehouseIcon } from './helpers';

export default class RoutePlanning {
	/**
	 * Formats the stops of a route as needed by the Directions class
	 * @param {array} stops - the route stops
	 * @returns the stops list formatted with its coordinates and icon
	 */
	static _formatStopsToDirections(stops = []) {
		return stops.map((stop = {}, idx = 0) => {
			const { type = '', coordinates = [], color } = stop;

			return {
				position: { lng: coordinates[0], lat: coordinates[1] },
				icon: {
					url: type === 'warehouse' ? getWarehouseIcon() : getShippingIcon(color ? idx : '', color)
				},
				...stop
			};
		});
	}

	/**
	 * Formats a routes list
	 * @param {array} routes - a list of routes
	 * @returns a list of formatted routes
	 */
	static async formatRoutes(routes = []) {
		return Promise.all(
			routes.map(async (route = {}, idx) => {
				const routeColor = route?.color || getColor();

				const newRoute = new RouteFormatter({ ...route, color: routeColor }).format();

				const points = this._formatStopsToDirections(newRoute?.stops);

				const { allCoords: polylines = [], routeData = {} } = await Directions.getDirections({
					points
				});

				return {
					...newRoute,
					color: routeColor,
					points,
					polylines,
					routeData,
					routeOrder: idx + 1
				};
			})
		);
	}

	/**
	 * Format a list of unassigned shippings to render in map
	 * @param {array} unassignedShippings - a list of unassigned shippings
	 * @returns a list of formatted unassigned shippings with points to render in map
	 */
	static transformUnassignedShippingsIntoMarkers(unassignedShippings = []) {
		return unassignedShippings.reduce((acc, shippingGroup) => {
			const formattedShippings = shippingGroup?.shippings?.reduce(
				(accum, shipping) => [...accum, Shipping.format(shipping)],
				[]
			);
			return [...acc, { points: this._formatStopsToDirections(formattedShippings) }];
		}, []);
	}

	/**
	 * Format a list of routes to be sent to Janis
	 * @param {array} routes - a list of routes
	 * @param {object} driversData - a route drivers information
	 * @returns a list of formatted routes adding the selected driver for each route
	 */
	static formatToJanis(routes = [], driversData = {}) {
		if (!routes.length) return [];
		return routes.map((route = {}) => {
			const selectedDriver = getSelectedDriverByWarehouse(
				`${route?.warehouse?.id}${route?.routeOrder}`,
				driversData
			);

			return {
				...(!route?.id.includes('createdRoute') && { id: route?.id }),
				...(selectedDriver?.value && { driverId: selectedDriver?.value }),
				vehicleTypeId: route?.vehicleType?.id,
				stops: route?.stops
			};
		});
	}
}
