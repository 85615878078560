import { format } from 'date-fns';

/**
 * Formats a date using date-fns.
 * @param {Date | string} date - The date to format.
 * @param {string} formatString - The format string (date-fns style).
 * @param {Object} [options={}] - Additional options, like locale.
 * @returns {string} - The formatted date.
 */
export default (date, formatString, options = {}) => format(new Date(date), formatString, options);
