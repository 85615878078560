import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { getLocationParts } from 'utils/location';
import { openSchemaModal, closeSchemaModal } from 'modules/navbar/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getFromStorage, setToStorage, deleteFromLocalStorage } from 'utils/storage';
import store from 'store';
import Button from 'components/Button';
import Modal from 'components/ActionsModal';
import Code from 'components/Code';

const ShowSchemaModal = ({ page, t }) => {
	const { showSchemaModal = false } = useSelector(state => state.navbar);
	const dispatch = useDispatch();
	const [service, namespace, method] = getLocationParts();
	const name = `cacheSchema-${service}/${namespace}/${method}`;

	const [schema, setSchema] = useState(getFromStorage(name));

	const availableSchema = schema && !isEmpty(schema);

	const pageSchema = page.schema;

	useEffect(
		() => {
			const newSchema = getFromStorage(name) || pageSchema;
			setSchema(newSchema);
		},
		[pageSchema]
	);

	const targetUrl = `/schemas/${service}/${namespace}/${method}.json`;

	const handleChange = value => {
		setToStorage(name, value, 30);
	};

	const handleRestart = () => {
		deleteFromLocalStorage(name);
		return window.location.reload();
	};

	const handleApplyChanges = () => {
		store.set('schemaCacheDisabled', false);
		window.location.reload();
	};

	const handleShowModal = () => {
		if (showSchemaModal) return dispatch(closeSchemaModal());
		return dispatch(openSchemaModal());
	};

	return (
		<>
			<Button rounded icon="eye" disabled={!availableSchema} onClick={handleShowModal} />
			<Modal
				isOpen={showSchemaModal}
				width="xl"
				title={t('common.title.pageSchema')}
				confirmButtonText="common.action.openInNewTab"
				handleCloseModal={handleShowModal}
				handleConfirm={() => {
					window.open(targetUrl, '_blank').focus();
				}}
			>
				<Code
					value={schema}
					resetCb={handleRestart}
					applyCb={handleApplyChanges}
					canEdit
					onChange={handleChange}
				/>
			</Modal>
		</>
	);
};

export default ShowSchemaModal;
