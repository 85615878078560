import fetchPolylines from './fetchPolylines';
import getAllWaypoints from './getAllWaypoints';
import splitWaypointsIntoChunks from './splitWaypointsIntoChunks';

/**
 * Retrieves the polyline directions from Google Maps API based on the provided parameters.
 * @param {Object} params - an object containing the parameters for the API request.
 * @returns {Promise<Array<Object>>} - a Promise that resolves to an array of coordinates.
 * @throws {Error} - if params is not a valid object or any of its properties are not valid.
 */
export default async (params = {}) => {
	try {
		if (!Object.keys(params).length) throw new Error('params is not a valid object');
		const {
			coordinates: { origin, destination },
			waypoints = [],
			googleMapsApiKey = '',
			directionsUrl = '',
			maxWaypointsPerRequest = 0
		} = params;

		if (!directionsUrl) throw new Error('No directions url provided');

		const allWaypoints = getAllWaypoints(origin, destination, waypoints);
		const waypointsChunks = splitWaypointsIntoChunks(allWaypoints, maxWaypointsPerRequest);

		return fetchPolylines(directionsUrl, waypointsChunks, googleMapsApiKey);
	} catch (reason) {
		return Promise.reject(reason?.response?.data || reason);
	}
};
