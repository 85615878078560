import styled from 'styled-components';
import AceEditor from 'react-ace';
import Button from 'components/Button';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/mode-mysql';
import 'ace-builds/src-noconflict/mode-diff';
import 'ace-builds/src-noconflict/mode-dockerfile';
import 'ace-builds/src-noconflict/mode-php';
import 'ace-builds/src-noconflict/mode-handlebars';
import 'ace-builds/src-min-noconflict/ext-searchbox';

export default {
	Wrapper: styled.div`
		width: 100%;
	`,
	CodeViewer: styled(AceEditor)`
		.ace_gutter {
			z-index: initial;
		}
	`,
	BaseButton: styled(Button)`
		margin-top: 15px;
		margin-left: auto;
	`,
	CopyButton: styled(Button)`
		margin-top: 15px;
		margin-left: auto;
	`
};
