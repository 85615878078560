export default class Map {
	/**
	 * Formats the points of a trail for rendering on the map
	 * @param {array} points - a list of markers
	 * @param {array} polylines - a list of coordinates
	 * @param {string} color - the color for the trail in the map
	 * @returns the points and the polyline configuration
	 */
	static format(points = [], polylines = [], color = '') {
		const polylineOptions = {
			strokeColor: color,
			strokeOpacity: 0.8,
			strokeWeight: 4
		};

		return {
			points,
			...(!!polylines.length && { polylines, polylineOptions })
		};
	}
}
