import * as types from './types';

const initialState = {
	data: {},
	error: false,
	isFetching: false,
	isReady: false,
	isModalOpen: false,
	visibleTab: '',
	errorData: {},
	saveEditDependencies: {}
};

export default function reducer(state = initialState, action) {
	const settingTypes = {
		[types.IS_READY]: { ...state, isReady: action.isReady },
		[types.FETCH_DATA_REQUEST]: { ...state, error: false, isFetching: true, isReady: false },
		[types.FETCH_DATA_SUCCESS]: {
			...state,
			data: action.data,
			error: false,
			isFetching: false,
			isReady: true
		},
		[types.FETCH_DATA_FAILURE]: {
			...state,
			error: true,
			isFetching: false,
			errorData: action.status,
			isReady: true
		},
		[types.SET_TAB_VISIBLE]: { ...state, visibleTab: action.name }
	};
	return settingTypes[action.type] || state;
}
