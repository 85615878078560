import {
	fetchRoutePlanningById,
	fetchRoutePlanningDetailsById,
	updateRoutes
} from 'pages/RoutePlanningEdit/utils';
import { addAlert } from 'modules/alerts/actions';
import { goToURL } from 'utils/location';
import simulateRouteByOptimize from 'services/tms/simulateRoute';
import RoutePlanning from 'libs/delivery/RoutePlanning';
import * as types from './types';

export const setPlanningData = data => ({
	type: types.FETCH_ROUTE_PLANNING_DATA,
	data
});

export const setFormattedRoutes = routes => ({
	type: types.SET_FORMATTED_ROUTES,
	routes
});

export const setIsLoading = isLoading => ({
	type: types.SET_LOADING,
	isLoading
});

export const setDataError = status => ({
	type: types.SET_ERROR,
	status
});

export const setFormattedUnassignedShippings = unassignedShippings => ({
	type: types.SET_FORMATTED_UNASSIGNED_SHIPPINGS,
	unassignedShippings
});

export const updateFormattedRouteById = (routeId, updatedRoute) => ({
	type: types.UPDATE_FORMATTED_ROUTE_BY_ID,
	routeId,
	updatedRoute
});

export const loadRoutePlanningData = id => async dispatch => {
	const { data, error } = await fetchRoutePlanningDetailsById(id);

	if (error) return dispatch(setDataError({ status: error }));

	dispatch(setPlanningData(data));
};

export const fetchRoutePlanningData = id => async dispatch => {
	if (!id) return;

	dispatch(setIsLoading(true));

	const { data, errors } = await fetchRoutePlanningById(id);

	if (errors.length || errors.includes('canceled'))
		return dispatch(setDataError({ status: errors[0] }));

	const { status = '' } = data || {};

	if (status === 'pendingConfirmation') return dispatch(loadRoutePlanningData(id));
	if (status !== 'processing') return dispatch(setDataError({ status: errors[0] || status }));

	setTimeout(() => dispatch(fetchRoutePlanningData(id)), 2000);
};

export const fetchRoutePlanningRoutesData = id => async dispatch => {
	if (!id) return;

	dispatch(setIsLoading(true));

	const { data, errors } = await fetchRoutePlanningById(id);

	if (errors.length || errors.includes('canceled'))
		return dispatch(setDataError({ status: errors[0] }));

	const { status = '' } = data || {};

	if (status !== 'creatingRoutes') {
		dispatch(setIsLoading(false));
		return true;
	}
	return new Promise(resolve => {
		setTimeout(async () => {
			const result = await dispatch(fetchRoutePlanningRoutesData(id));
			resolve(result);
		}, 2000);
	});
};

export const dispatchAlert = (id, type, message) => dispatch =>
	dispatch(addAlert({ id, type, message }));

export const createRoutes = (id, dataToSend, t, savingRoutes = false) => async dispatch => {
	dispatch(setIsLoading(true));

	const response = await updateRoutes(dataToSend, id);

	if (response?.error) {
		dispatch(dispatchAlert('route-planning__create-route-error', 'error', response?.error));
		dispatch(setIsLoading(false));
		return;
	}
	dispatch(
		dispatchAlert('route-planning__create-route-success', 'success', t('views.route.routeUpdated'))
	);
	if (savingRoutes) return goToURL(`/tms/route-planning/view/${id}`);
	const routesReady = await dispatch(fetchRoutePlanningRoutesData(id));
	if (routesReady) goToURL(`/tms/route-planning/view/${id}`);
};

export const updateUnassignedShippings = (locationId = '', conditionFn = () => {}) => (
	dispatch,
	getState
) => {
	const {
		routePlanning: { formattedUnassignedShippings }
	} = getState();
	dispatch(
		setFormattedUnassignedShippings(
			formattedUnassignedShippings
				.map(group =>
					group?.warehouse?.id === locationId
						? {
								...group,
								shippings: group?.shippings?.filter(conditionFn)
						  }
						: group
				)
				.filter(group => !!group?.shippings?.length)
		)
	);
};

export const getOptimizedEditStops = (body, route) => async dispatch => {
	try {
		const response = await simulateRouteByOptimize(body);

		const { stops } = response || {};

		const formattedUpdatedRoute = await RoutePlanning.formatRoutes([
			{
				...route,
				stops
			}
		]);

		dispatch(updateFormattedRouteById(route.id, formattedUpdatedRoute[0]));

		return {
			stops
		};
	} catch ({ response = {} }) {
		dispatch(
			addAlert({
				message: response?.data?.message,
				type: 'error',
				id: `simulate_api_error_message`
			})
		);
	}
};
