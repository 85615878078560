import Icon from 'components/Icon';
import { mediaBreaks } from 'devices';
import styled from 'styled-components';
import { colors } from 'theme';

export default {
	Container: styled.div`
		position: fixed;
		top: 0px;
		background-color: ${colors.red};
		height: 64px;
		width: 100%;
		display: flex;
		flex-direction: row;
		align-items: center;
		z-index: 2064;
		padding: 8px 29px;

		${mediaBreaks.mobile`
			padding: 12px 15px;
		`};

		span {
			line-height: 14px;
		}

		.text-wrapper {
			max-width: unset;

			span {
				margin-left: 0;
				line-height: 14px;
			}
		}
	`,
	Icon: styled(Icon)`
		margin-right: 8px;

		${mediaBreaks.mobile`
			margin-right: 12px;
		`};
	`,
	BoldText: styled.span`
		font-weight: bold;
	`
};
