import { isNumber } from 'utils/number';

/**
 * Checks if the coordinates of a shipping are valid
 * @param {array} coordinates - the coordinates of a current shipping
 * @returns true if the coordinates are valid, otherwise false
 */
export default (coordinates = []) => {
	if (!Array.isArray(coordinates) || coordinates.length !== 2) return false;

	const [longitude, latitude] = coordinates;

	if (!isNumber(latitude) || !isNumber(longitude)) return false;

	const isValidLatitude = latitude >= -90 && latitude <= 90;
	const isValidLongitude = longitude >= -180 && longitude <= 180;

	return isValidLatitude && isValidLongitude;
};
