import filterRouteStopsByType from './filterRouteStopsByType';

/**
 * Gets the total stops quantity from all routes
 * @param {object[]} routes - the routes list
 * @returns {number} - total stops quantity
 */
export default (routes = []) =>
	routes.reduce(
		// eslint-disable-next-line no-return-assign, no-param-reassign
		(acc, route) => (acc += filterRouteStopsByType(route?.stops, 'shipping')?.length),
		0
	);
