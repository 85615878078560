import { callRequest } from 'utils/request';

const source = {
	service: 'tms',
	namespace: 'route',
	method: 'simulate'
};

const simulateRouteByOptimize = async body => {
	try {
		if (!body) throw new Error({ response: {}, error: 'No request body provided' });

		const response = await callRequest(source, body);

		return response;
	} catch ({ response = {} }) {
		return { response: null, error: response?.data?.message };
	}
};

export default simulateRouteByOptimize;
