import { createSelector } from 'reselect';
import { getFormValues } from 'redux-form';
import {
	getValueForSelect,
	isObject,
	formatMapperTemplate,
	formatRecordData,
	makePrefixedValue
} from 'utils';
import { getSchema, getFieldsWithLabels } from 'utils/selectors';
import {
	fieldsGroupFields,
	defaultComponentsValuesMapper,
	formatValue,
	formattedInitialValues
} from 'utils/forms/forms';
import { getPresets, datePresets } from 'utils/dates';
import { makeTemplateValue } from 'utils/translateComponent';
import { get as lodashGet, set as lodashSet, isEmpty, flatten, merge } from 'lodash';
import { parse } from 'qs';
import { processConditionals } from 'utils/conditions';
import { parseDatesValues } from 'components/DateTimePicker/utils';

export const getCurrentFormSection = (state, props) => state.formSections[props.formSection] || {};
export const getData = (state, props) => getCurrentFormSection(state, props).data;
export const getGlobalDependencies = state => state.editCreate.data.globalDependencies || {};
const getSectionSchema = (state, props) => getCurrentFormSection(state, props).schema;

/**
 * Make Value o Values for Select and Multiselect
 * @param {Object} formData
 * @param {!Object} fieldValue
 * @param {Object} selectProps
 */
export const getSelectValue = (data, fieldValue, selectProps) => {
	const {
		component,
		name,
		componentAttributes: { labelFieldName, translateLabels, labelPrefix, options = {} }
	} = selectProps;

	const isSelectMultilevel = component === 'SelectMultilevel';
	const isStatusSelector = component === 'StatusSelector';

	const { values, valuesMapper = {} } = options;

	const translate = (val, customPrefix) => {
		const translateLabel = translateLabels || !!customPrefix;

		const prefixes = labelPrefix || customPrefix;

		const label = makePrefixedValue(name, val.label, translateLabel, prefixes);

		const formattedOption = { ...val, label };

		return translateLabel ? getValueForSelect(formattedOption) : formattedOption;
	};

	const findValueInOptions = itemValue => {
		if (!Array.isArray(values)) return;

		return values.find(option => option.value === itemValue);
	};

	// format label for select
	const getSelectLabel = value => {
		const { label: labelMapper } = valuesMapper;
		const isObjectValue = isObject(value);

		const isObjectMapper = isObject(labelMapper);

		if (isObjectMapper && isObjectValue)
			return formatMapperTemplate(labelMapper, value, makeTemplateValue);

		return isObjectValue ? value[labelMapper] || data[labelFieldName] : value.toString();
	};

	// If is array of vaules make array with label value mapper
	if (Array.isArray(fieldValue)) {
		const arrayValue = fieldValue.map(val => {
			const { value: valueMapper = 'value' } = valuesMapper;

			const isObjectValue = typeof val === 'object';

			const label = getSelectLabel(val);
			const value = isObjectValue ? val[valueMapper] : val;

			const filterOption = findValueInOptions(value);

			if (filterOption) return translate(filterOption);

			return translate({
				label,
				value
			});
		});

		if (isSelectMultilevel)
			Object.defineProperty(arrayValue, 'last', { value: arrayValue[arrayValue.length - 1] });

		return arrayValue;
	}

	// If has options in values find and return that
	if (values && values.length) {
		const value = findValueInOptions(fieldValue);
		return value && translate(value);
	}

	// If not has options make label value object and return that
	const label = data[labelFieldName] || fieldValue.toString();
	const value = { label, value: fieldValue };

	if (isStatusSelector) return translate(value, 'common.status.');

	if (isSelectMultilevel) {
		const arrayValue = [value];

		Object.defineProperty(arrayValue, 'last', { value });

		return arrayValue;
	}

	return data[labelFieldName] ? translate(value) : value;
};

/**
 * Function for format values for Forms Fields
 * @param {object} formData
 * @param {any} fieldValue
 * @param {Objectt} fieldData
 */
const formatValueData = (data, fieldValue, fieldData) => {
	if (!fieldData) return fieldValue;

	const { component } = fieldData;

	const isSelect = /select/gi.test(component);

	if (
		isSelect &&
		(!isObject(fieldValue) || Array.isArray(fieldValue)) &&
		fieldValue !== null &&
		fieldValue !== undefined
	)
		return getSelectValue(data, fieldValue, fieldData);

	if (component === 'FieldsArray' && fieldValue) {
		const { fields, uniqueField } = fieldData.componentAttributes;

		if (uniqueField) return [...fieldValue];

		return fieldValue.map(itemData => {
			if (!fields.length) return itemData;

			const formatedData = fields.reduce((accum, itemField) => {
				const accumulator = { ...accum };

				const innerFieldValue = lodashGet(itemData, itemField.name);

				if (innerFieldValue !== undefined)
					lodashSet(accumulator, itemField.name, formatValueData(data, innerFieldValue, itemField));

				return accumulator;
			}, {});

			return { ...itemData, ...formatedData };
		});
	}

	if (fieldValue && component === 'DateTimePicker') {
		const { selectRange, setStartOfDay, setEndOfDay, selectTime } = fieldData?.componentAttributes;

		const presetsKeys = datePresets && Object.keys(datePresets);

		if (!selectRange)
			return presetsKeys.find(key => key === fieldValue)
				? parseDatesValues(fieldValue, presetsKeys, false, setStartOfDay, setEndOfDay, selectTime)
				: fieldValue;

		const { from, to } = fieldValue || {};

		if (from && to && datePresets[from] && datePresets[to]) return getPresets(fieldValue);

		return fieldValue;
	}

	return fieldValue;
};

const addValuesWithDotNotation = ({ accumName, accumValues, values, fieldsData }) => {
	const addValuesWithDotNotationHelper = keyName => {
		const fieldData = lodashGet(fieldsData, keyName);

		const fieldValue = lodashGet(values, keyName);

		if (isObject(fieldValue) && !fieldData) {
			Object.keys(fieldValue).forEach(keyValue => {
				const currentName = `${keyName}.${keyValue}`;

				addValuesWithDotNotationHelper(currentName);
			});
		} else lodashSet(accumValues, keyName, formatValueData(values, fieldValue, fieldData));
	};

	addValuesWithDotNotationHelper(accumName);

	return accumValues;
};

/**
 * Function for create formattedData from defaultvalues in fieldsArray
 * @param {Array} fieldsArray
 * @param {number} minElements
 */
const fieldsArrayDefaultValues = (fieldsArray, minElements = 1) => {
	const defaultObject = fieldsArray.reduce((accumulator, { name, defaultValue }) => {
		if (defaultValue) return { ...accumulator, [name]: defaultValue };
		return accumulator;
	}, {});

	const hasValidDefaultValues = Object.keys(defaultObject).length > 0;
	if (!hasValidDefaultValues) return [];

	return Array.from({ length: minElements }, () => ({ ...defaultObject }));
};

/**
 * Separates a text with dots and runs it from right to left to validate and save the data correctly if dotnotation exists
 * @param {string} fieldKey
 * @param {object} value
 * @param {string | boolean} currentValue
 */
const validateNotationObject = (fieldKey, value, currentValue) => {
	if (value === undefined) return currentValue;
	const parts = fieldKey.split('.');
	const accum = parts.reduceRight((acc, part) => ({ [part]: acc }), value);
	return merge(currentValue, accum);
};

/**
 * Function for add defaultValues and its keys if not exist in data
 * @param {object} data
 * @param {object} fieldsSchema
 */
const mergeDefaultValuesToData = (data, fieldsSchema) => {
	const defaults = Object.entries(fieldsSchema).reduce((acc, [key, value]) => {
		const currentValue =
			value.component === 'FieldsArray'
				? fieldsArrayDefaultValues(
						value.componentAttributes.fields,
						value.componentAttributes.minElements
				  )
				: value.defaultValue;

		return merge(acc, validateNotationObject(key, currentValue, acc));
	}, {});

	return merge({}, defaults, data);
};

// Selector for format correctly values for accept form
export const formatData = () =>
	createSelector(
		[getData, getSectionSchema],
		(data, schema) => {
			if (!data) return;

			const { fieldsGroup } = schema;
			const fieldsData = fieldsGroupFields(fieldsGroup);
			const mergedData = mergeDefaultValuesToData(data, fieldsData);

			const dataFormatted = Object.keys(mergedData).reduce((acc, fieldName) => {
				const formattedData = { ...acc };

				const dataValues = addValuesWithDotNotation({
					accumName: fieldName,
					accumValues: formattedData,
					values: mergedData,
					fieldsData
				});

				return dataValues;
			}, {});

			return dataFormatted;
		}
	);

/**
 * Get fieldgroup with data in fields
 */
export const fieldsGroup = () =>
	createSelector(
		[getData, getSectionSchema, getSchema],
		(data, schema, rootSchema) => {
			const { root } = rootSchema;

			/**
			 * Add properties for selects components when certain conditions are meet
			 * @param {object} field
			 * @returns {object}
			 */
			const addSelectsModifications = field => {
				const currentField = { ...field };

				const { component, componentAttributes, validations: rawValidations } = currentField;

				const alwaysRemoteComponents = ['SelectForm', 'UserSelector'];

				const availableComponents = [
					...alwaysRemoteComponents,
					'Select',
					'Multiselect',
					'IconSelector'
				];

				const isAvailableComponent = availableComponents.includes(component);

				if (isAvailableComponent && rawValidations && componentAttributes) {
					const validations = flatten(rawValidations);

					const isRequired = validations.some(validation => validation.name === 'required');

					if (isRequired) {
						currentField.componentAttributes.canClear = false;

						const isRemote =
							(componentAttributes.options && componentAttributes.options.scope === 'remote') ||
							alwaysRemoteComponents.includes(component);
						if (
							/create/gi.test(root) &&
							isRemote &&
							!('preloadOptions' in currentField.componentAttributes)
						)
							currentField.componentAttributes.preloadOptions = true;
					}
				}

				return currentField;
			};

			const fieldsGroupWithData = schema.fieldsGroup.map(group => {
				const currentGroup = { ...group };

				const fields = currentGroup.fields.map(field => {
					const currentField = { componentAttributes: {}, ...field };

					if (!isEmpty(data)) currentField.data = lodashGet(data, currentField.name);

					if (currentField.component === 'FieldsArray') {
						currentField.componentAttributes.fields = currentField.componentAttributes.fields.map(
							addSelectsModifications
						);
						return currentField;
					}

					return addSelectsModifications(currentField);
				});

				currentGroup.fields = fields;

				return currentGroup;
			});

			const { hideUserCreated, hideUserModified } = schema;

			if (!isEmpty(data)) {
				const recordData = formatRecordData(data, hideUserCreated, hideUserModified);
				return fieldsGroupWithData.concat(recordData);
			}

			return fieldsGroupWithData;
		}
	);

const formatDataValues = (values = {}) =>
	Object.keys(values).reduce(
		(accum, key) => ({
			...accum,
			[key]: formatValue(values[key])
		}),
		{}
	);

/**
 * Make a object with current forms values
 * @param {object} state - redux state
 * @param {string} formName - form name
 * @returns {object}
 */
export const getAdditionalFormSectionsValues = (state, formName) => {
	const { editCreate, form } = state;

	const { globalDependencies } = editCreate.data;

	const mainFormKey = 'mainFormSection';
	const globalDependenciesKey = 'globalDependencies';

	const formKeys = [...Object.keys(form), 'globalDependencies'];

	const keys = formKeys.includes(mainFormKey) ? formKeys : [...formKeys, mainFormKey];

	return keys.reduce((accum, keyForm) => {
		const accumulator = { ...accum };
		let formData = getFormValues(keyForm)(state);

		const useMainFormInitialData = keyForm === mainFormKey && !formData;

		if (keyForm === globalDependenciesKey) formData = globalDependencies;

		if (useMainFormInitialData) formData = editCreate.data;

		// Add values for all forms in keys form names
		if (formData && !isEmpty(formData)) {
			const values = useMainFormInitialData ? formData : formatDataValues(formData);

			// Includes values from especific form
			if (formName && keyForm === formName) return { ...values, ...accumulator };

			return { ...accumulator, [keyForm]: values };
		}

		return accumulator;
	}, {});
};

// Function for get label value for fields (input|select)
export const getGroupFields = () => getFieldsWithLabels(getSectionSchema);

export const getCurrentFormSectionValues = () =>
	createSelector(
		[
			(state, props) => {
				const { name } = props.data;
				return getAdditionalFormSectionsValues(state, name);
			}
		],
		formsValues => formsValues
	);

/**
 * Get form section values for Form initialValues
 */
export const getFormSectionDefaultValues = () =>
	createSelector(
		[getSectionSchema, state => getAdditionalFormSectionsValues(state)],
		(schema, formValues) => {
			const { initialValues: rawInitialValues = {} } = parse(window.location.search, {
				ignoreQueryPrefix: true
			});

			const triggerList = [];

			const formData = { ...formValues };

			return schema.fieldsGroup.reduce((accum, fieldsGroupItem) => {
				const accumulator = { ...accum };

				const {
					conditions: fieldGroupConditions,
					name: fieldName,
					prefix: fieldPrefix
				} = fieldsGroupItem;
				const { showWhen: showFieldGroupWhen = undefined } = fieldGroupConditions || {};

				let showFieldGroup = true;

				if (showFieldGroupWhen) {
					showFieldGroup = processConditionals({
						andConditionals: showFieldGroupWhen,
						data: formData[schema.name],
						prefix: fieldPrefix,
						matchFieldName: fieldName
					});
				}

				fieldsGroupItem.fields.forEach(field => {
					/* Initial value from url */
					const initialValues =
						rawInitialValues[field.name] !== undefined
							? formattedInitialValues(field, rawInitialValues)
							: rawInitialValues;
					/* get dynamic default value */
					if (schema?.currentViewData?.relatedData)
						formData.parentData = schema.currentViewData.relatedData;

					const dynamicDefaultValue = lodashGet(formData, field.defaultValueField);
					const currentFormValue = lodashGet(formData[schema.name], field.name);

					const validatedDynamicDefaultValue =
						dynamicDefaultValue === 0 || dynamicDefaultValue
							? dynamicDefaultValue
							: field.defaultValue;

					/** The initial value could be coming from either the url or the schema */
					const initialValue =
						initialValues[field.name] !== undefined
							? initialValues[field.name]
							: validatedDynamicDefaultValue;

					const defaultValue =
						initialValue !== undefined
							? formatValueData(initialValues, initialValue, field)
							: defaultComponentsValuesMapper[field.component];

					const { conditions, name, prefix, defaultValueField, triggers } = field;

					const dataMappings = triggers && triggers.map(trigger => trigger.dataMapping);

					if (triggers && dataMappings) {
						dataMappings.forEach(dataMappingItem => {
							const dataMappingEntries = Object.entries(dataMappingItem || {});
							dataMappingEntries.forEach(dataMappingEntrie => {
								const [, value] = dataMappingEntrie;
								triggerList.push(value);
							});
						});
					}

					const { showWhen = undefined } = conditions || {};

					let show = true;

					const hasParentData = defaultValueField?.includes('parentData.');

					if (showWhen)
						show = processConditionals({
							andConditionals: showWhen,
							data: hasParentData ? formData : formData[schema.name],
							prefix,
							matchFieldName: name
						});

					const valueToSave = currentFormValue === undefined ? defaultValue : currentFormValue;

					const hasTrigger = triggerList.find(trigger => trigger === name);
					if (show && showFieldGroup && !hasTrigger) {
						lodashSet(accumulator, name, valueToSave !== undefined ? valueToSave : null);
					}
				});
				return accumulator;
			}, formData[schema.name] || {});
		}
	);

/**
 * Get form section values for Form min max field values
 */
export const getFormSectionMinMaxValues = fieldComponent =>
	createSelector(
		[getSectionSchema, (state, props) => getAdditionalFormSectionsValues(state, props.formSection)],
		(schema, formValues) =>
			schema.fieldsGroup.reduce((accum, fieldsGroupItem) => {
				const accumulator = { ...accum };
				const formDataValues = formValues;

				formDataValues.parentData = schema.currentViewData?.relatedData || schema.currentViewData;

				fieldsGroupItem.fields
					.filter(field => field.name === fieldComponent.name)
					.forEach(field => {
						const { minValueField, maxValueField } = field.componentAttributes || {};

						/* get dynamic default value */
						const dynamicMinValue = lodashGet(formDataValues, minValueField);
						const dynamicMaxValue = lodashGet(formDataValues, maxValueField);

						if (dynamicMaxValue || !Number.isNaN(Number(dynamicMaxValue))) {
							lodashSet(accumulator, field.name, { min: dynamicMinValue, max: dynamicMaxValue });
						}
					});
				return accumulator;
			}, {})
	);
