import { get } from 'lodash';
import store from 'store';
import Decimal from 'decimal.js';
import { getAuthCookieDecoded } from './auth';

/**
 * Format number to string currency
 * @param {number} value
 * @param {object} props
 * @param {object} data
 * @returns {string}
 */
export const numberToCurrency = (value, props = {}, data = {}) => {
	const { currencyField } = props;

	if (typeof value === 'undefined' || value === null) return value;

	const { tcurrency, tcurrencyDisplay, locale } = getAuthCookieDecoded() || {};

	const localeSelected = store.get('currentLanguage') || locale || undefined;

	let formatter;

	try {
		formatter = new Intl.NumberFormat(localeSelected, {
			style: 'currency',
			currency: get(data, currencyField || 'currency') || tcurrency,
			currencyDisplay: tcurrencyDisplay || 'symbol'
		});
	} catch {
		formatter = new Intl.NumberFormat(localeSelected, {
			style: 'currency',
			currency: 'USD'
		});
	}

	return formatter.format(value);
};

/**
 * Format number to string number formmatted
 * @param {number} value
 * @returns {string}
 */
export const formatNumber = value => new Intl.NumberFormat().format(value);

/**
 * Fixed number value
 * @param {number} value
 * @param {number} digits
 * @returns {number}
 */
export const toFixed = (value, digits) =>
	typeof value === 'number' ? parseFloat(value.toFixed(digits)) : value;

export const sum = (values = []) =>
	Decimal.sum(...values.map(value => new Decimal(value))).toNumber();

export const multiply = (values = []) =>
	values.reduce((acc, value) => acc.mul(new Decimal(value)), new Decimal(1)).toNumber();

export const sub = (firstValue, secondValue) => Decimal.sub(firstValue, secondValue).toNumber();

export const isNumber = num => typeof num === 'number' && !Number.isNaN(Number(num));

/**
 * It takes a number and rounded it to down
 * @param  {number} value
 * @returns {number}
 */
export const roundAmount = value => {
	if (typeof value !== 'number' || Number.isNaN(Number(value))) return null;
	const valueToRound = new Decimal(value);
	return valueToRound.toDP(2, Decimal.ROUND_DOWN).toNumber();
};

/**
 * Converts a formatted string with `.` or `,` into a valid number
 * @param {string} value - User input
 * @returns {number | null}
 */
export const parseNumberInput = value => {
	const currentValue = String(value);
	if (!currentValue) return null;

	const normalized = currentValue?.replace(',', '.');
	const num = parseFloat(normalized);
	return !isNumber(num) ? null : num;
};

/**
 * Checks if the input contains any invalid characters.
 *
 * Valid characters: digits (0-9), comma (,), and dot (.)
 *
 * @param {string} value - The input string to validate.
 * @returns {boolean} - Returns `true` if the input contains invalid characters, otherwise `false`.
 */
export const hasInvalidText = value => /[^\d.,]/.test(value);

/**
 * Validates whether a given string follows a correct numeric format.
 * The input must:
 * - Contain only digits and at most one decimal separator (`.` or `,`).
 * - Not contain both `.` and `,` simultaneously.
 *
 * @param {string} value - The user input to validate.
 * @returns {boolean} - `true` if the input format is valid, otherwise `false`.
 */
export const isValidNumberFormat = value => {
	const regex = /^\d+([.,]\d+)?$/;

	const hasDot = value.includes('.');
	const hasComma = value.includes(',');

	if (hasDot && hasComma) return false;

	return regex.test(value);
};
