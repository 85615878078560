import createCoordsFromWaypoints from './createCoordsFromWaypoints';
import formatCoordinates from './formatCoordinates';

/**
 * Format coordinates to be used in the map
 * @param {Object} origin - object with the origin data
 * @param {Object} destination - object with the destination data
 * @param {Array} waypoints - list of waypoints
 * @returns Returns the list of waypoints
 */
export default (origin = {}, destination = {}, waypoints = []) => [
	formatCoordinates(origin?.lat || origin?.position?.lat, origin?.lng || origin?.position?.lng),
	...createCoordsFromWaypoints(waypoints),
	formatCoordinates(
		destination?.lat || destination?.position?.lat,
		destination?.lng || destination?.position?.lng
	)
];
