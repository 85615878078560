import { getLocationParts } from 'utils/location';
import { callRequest } from 'utils/request';

const source = {
	service: 'tms',
	namespace: 'route-planning',
	method: 'routes'
};

export default async requestBody => {
	try {
		const id = getLocationParts()[3];
		const response = await callRequest(source, requestBody, { id });
		return { data: response };
	} catch (error) {
		console.error('Error at create routes', error);
		return { data: {}, error: error?.message };
	}
};
